.discipline-level {
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}
.discipline-level__list {
  margin: 8px 0px 0px 0px;
  padding: 0px;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  overflow-y: scroll;
}
.discipline-level__row {
  margin: 0px 12px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
  width: calc(100% - 12px);
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
}
.discipline-level__row_type_stub {
  margin: 20px 24px 0px 0px;
  padding: 4px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--background-color);
  width: calc(100% - 24px);
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
}
.discipline-level__semesters {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
}
.discipline-level__column-title {
  margin: 0px;
  font-style: bold;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--main-black-color);
}
.discipline-level__column {
  margin: 0px;
  padding: 8px 0px;
  box-sizing: border-box;
}
.discipline-level__column_type_process {
  margin: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 1;
}
.discipline-level__column_type_discipline {
  margin: 0px 20px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}
.discipline-level__column_type_semester {
  width: 54px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  font-style: bold;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: var(--main-black-color);
  border: 1px solid var(--background-color);
  border-radius: var(--border-radius);
  position: relative;
}
.discipline-level__column_type_semester_type_stub {
  flex-shrink: 0;
  position: relative;
}
.discipline-level__process-list {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
.discipline-level__process-item {
  margin: 0px 0px 16px 0px;
  display: flex;
  align-items: center;
}
.discipline-level__process-item:last-of-type {
  margin: 0px;
}
.discipline-level__card {
  width: 100%;
  margin: 0px;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: var(--main-grey-color);
  border-radius: var(--border-radius);
  position: relative;
  flex-grow: 2;
}
.discipline-level__card:hover .levels__item-header-btn-container {
  display: flex;
}
.discipline-level__card_type_stage {
  background-color: rgba(79, 147, 226, .2);
}
.discipline-level__card_type_warning {
  background-color: rgba(220, 101, 75, .2);
}
.discipline-level__card_type_success {
  background-color: rgba(62, 203, 110, .2);
}
.discipline-level__card-icon {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 4px;
  right: 8px;
}
.discipline-level__card-icon_type_stage {
  background-image: url('../../../images/phases.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.discipline-level__card-icon_type_module {
  background-image: url('../../../images/module.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.discipline-level__card-header {
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: center;
}
.discipline-level__buttons {
  margin: 0px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 6px;
}
.discipline-level__card-title {
  margin: 16px 0px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
}
.discipline-level__card-arrow {
  margin: 0px 12px;
  width: 32px;
  height: 32px;
  background-image: url('../../../images/arrow-right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
}
.discipline-level__card-nested {
  margin: 0px 12px 0px 0px;
  width: 32px;
  height: 32px;
  background-image: url('../../../images/arrow-nested.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
}
.discipline-level__semester-count {
  margin: 0 auto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}
.discipline-level__semester-circle {
  margin: 0px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: all .3s ease-in-out;
}
.discipline-level__semester-circle:hover {
  cursor: pointer;
}
.discipline-level__semester-circle_type_active {
  background: var(--gradient-blue-color);
}
.discipline-level__semester-circle_type_stub {
  background: var(--border-color);
}
