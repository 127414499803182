.answer__container {
  margin: 0px 0px 15px 0px;
  display: flex;
  align-items: center;
  position: relative;
}
.one-answer__radio {
  margin: 0px;
}
.one-answer__radio input + span {
  margin-top: -10px;
}
.answer__textarea {
  resize: none;
  width: calc(100% - 20px);
  padding: 9px 16px 9px 16px;
  margin: 0px 12px 0px 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  color: var(--title-color);
  border: 2px solid var(--background-color);
  background: var(--main-white-color);
  border-radius: var(--border-radius);
  transition: all .3s ease-in-out;
}
.answer__textarea:focus {
  border: 2px solid var(--accent-blue-color);
  outline: none;
}
.answer__textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--accent-grey-color);
}
.multi-answer {
  margin: 0px 0px 15px 0px;
  display: flex;
  align-items: center;
  position: relative;
}
.multi-answer__checkbox {
  margin: 0px;
}
.multi-answer__checkbox input + span {
  margin-top: -10px;
}
.answer__count {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--accent-grey-color);
}
.answer__buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}
