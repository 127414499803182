.page {
  --background-color: #E5E5E5;
  --main-grey-color: #F1F3F5;
  --main-white-color: #FFFFFF;
  --main-black-color: #0B0A1A;
  --accent-grey-color: #A6A6A6;
  --accent-blue-color: #837AEA;
  --accent-orange-color: #ff7b02;
  --accent-green-color: #95F4A4;
  --gradient-blue-color: linear-gradient(240deg, #5E5AC5 13%, #837AEB 100%);
  --gradient-green-color: linear-gradient(315deg, #7CCF8D 13%, #95F4A4 100%);
  --gradient-orange-color: linear-gradient(315deg,#ffcb53,#ff7b02 74.48%);
  --slider-color: #D9D9D9;
  --border-color: #F2F2F2;
  --border-radius: 12px;

  --product-color: #D249DE;
  --stage-color: #4F93E2;
  --process-color: #DC654B;
  --ability-color:  #837AEA;
  --knowledge-color: #E1B648;
  --discipline-color: #3ECB6E;
  --warning-color: #DE5050;

  color: var(--black-color);
  background-color: var(--background-color);
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  font-family: 'Roboto', Arial, sans-serif; 
  font-weight: normal;
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility; 

  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  overflow: hidden;
}
.wrapper {
  margin-left: calc(100vw - 100%);
  width: calc(200% - 100vw);
}
.container {
  max-width: 100%;
  width: 100%;
  padding: 12px 34px;
  margin: 0px auto;
  display: flex;
  box-sizing: border-box;
  position: relative;
}
.main-container {
  margin: 0px 0px 0px 276px;
  width: 100%;
}
.section {
  background: var(--main-white-color);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  position: relative;
}
.link {
  max-width: min-content;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--accent-blue-color);
  transition: all .3s ease-in-out;
}
.link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.icon {
  position: relative;
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  background-color: inherit;
  flex-shrink: 0;
  text-decoration: none;
  transition: all .3s ease-in-out;
}
.icon:hover {
  cursor: pointer;
  user-select: auto;
  pointer-events: all;
  opacity: .8;
}
.icon_margin_left-8 {
  margin-left: 8px;
}
.icon_margin_left-12 {
  margin-left: 12px;
}
.icon_size_14 {
  width: 14px;
  height: 14px;
  background-size: 14px;
}
.icon_size_16 {
  width: 16px;
  height: 16px;
  background-size: 16px;
}
.icon_size_18 {
  width: 18px;
  height: 18px;
  background-size: 18px;
}
.icon_size_20 {
  width: 20px;
  height: 20px;
  background-size: 20px;
}
.icon_type_add-grey {
  background-image: url('../../images/icons/accent-grey/add.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon_type_edit-grey {
  background-image: url('../../images/icons/accent-grey/edit.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon_type_remove-grey {
  background-image: url('../../images/icons/accent-grey/remove.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon_type_lock-grey {
  background-image: url('../../images/icons/accent-grey/lock.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon_type_unlock-grey {
  background-image: url('../../images/icons/accent-grey/unlock.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon_type_shuffle-grey {
  background-image: url('../../images/icons/accent-grey/shuffle.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon_type_link-grey {
  background-image: url('../../images/icons/accent-grey/link.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon_type_open-grey {
  background-image: url('../../images/icons/accent-grey/open.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon_type_time-grey {
  background-image: url('../../images/icons/accent-grey/time.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.badge__list {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
}
.badge__arrows {
  margin: 0 0 0 4px;
  width: 14px;
  height: 14px;
  background-image: url('../../images/arrows.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.badge {
  margin: 0px;
  width: fit-content;
  padding: 6px 18px;
  position: relative;
  box-sizing: border-box;
  font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	color: var(--main-black-color);
  border-radius: var(--border-radius);
  flex-shrink: 0;
  cursor: default;
  display: flex;
  align-items: center;
}
.badge_size_small {
  padding: 4px 12px;
  font-size: 12px;
	line-height: 12px;
}
.badge_margin_top_12 {
  margin-top: 12px;
}
.badge_margin_top_20 {
  margin-top: 20px;
}
.badge_margin_left_12 {
  margin-left: 12px;
}
.badge_margin_right_8 {
  margin-right: 8px;
}
.badge_type_blue {
	background-color: var(--accent-blue-color);
  color: var(--main-white-color);
  box-shadow: 0px 8px 12px -8px rgba(93, 90, 196, 0.9);
}
.badge_type_green {
	background-color: var(--accent-green-color);
  color: var(--main-black-color);
  box-shadow: 0px 8px 12px -8px rgba(149, 244, 164, 0.9);
}
.badge_type_orange {
	background-color: var(--accent-orange-color);
  color: var(--main-white-color);
  box-shadow: 0px 8px 12px -8px rgba(255, 123, 2, 0.9);
}
.badge_type_white {
	background-color: var(--main-white-color);
  color: var(--main-black-color);
}
.badge_type_product {
	background-color: var(--product-color);
  color: var(--main-white-color);
}
.badge_type_stage {
	background-color: var(--stage-color);
  color: var(--main-white-color);
}
.badge_type_process {
	background-color: var(--process-color);
  color: var(--main-white-color);
}
.badge_type_ability {
	background-color: var(--ability-color);
  color: var(--main-white-color);
}
.badge_type_knowledge {
	background-color: var(--knowledge-color);
  color: var(--main-white-color);
}
.badge_type_discipline {
	background-color: var(--discipline-color);
  color: var(--main-white-color);
}
.badge_type_warning {
	background-color: var(--warning-color);
  color: var(--main-white-color);
}
.badge-btn {
  text-align: left;
  padding: 6px 18px 6px 38px;
  border: none;
  outline: none;
  transition: all .3s ease-in-out;
}
.badge-btn:hover {
  cursor: pointer;
  opacity: .7;
}
.badge-btn_type_add::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 10px;
  width: 20px;
  height: 20px;
  background-image: url('../../images/icons/accent-grey/add.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}
.badge-btn_type_add-white::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 10px;
  width: 20px;
  height: 20px;
  background-image: url('../../images/icons/main-white/add.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}
 
.scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.scroll::-webkit-scrollbar-track {
  border-radius: var(--border-radius);
  background: var(--main-grey-color);
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius);
  background: var(--slider-color);
}

.scroll-inside::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.scroll-inside::-webkit-scrollbar-track {
  border-radius: var(--border-radius);
  background: var(--slider-color);
}
.scroll-inside::-webkit-scrollbar-thumb {
  border-radius: var(--border-radius);
  background: var(--accent-grey-color); 
}

input::-webkit-date-and-time-value {
  text-align: left;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.btn-add-round {
  position: relative;
  margin: 0px 0px 0px 12px;
  padding: 0px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  border: none;
  outline: none;
  background-image: url('../../images/icons/accent-blue/add.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: inherit;
  transition: all .3s ease-in-out;
}
.btn-add-round:hover {
  opacity: .8;
  cursor: pointer;
  box-shadow: 0px 8px 12px -8px rgba(0, 55, 196, 0.9);
}
.btn-add-round_margin_auto {
  margin: 0px 0px 0px auto;
}
.btn_type_large {
  width: 180px;
  padding: 11px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  transition: all .3s ease-in-out;
  flex-shrink: 0;
}
.btn_type_link {
  margin: 0px;
  text-decoration: none;
}
.btn-cancel_type_large {
  margin: 0px 12px 0px 0px;
  background: inherit;
  border: 1px solid var(--slider-color);
  border-radius: var(--border-radius);
  color: var(--main-black-color);
}
.btn-cancel_type_large:hover  {
  font-weight: bold;
  cursor: pointer;
  user-select: auto;
  pointer-events: all;
  opacity: .8;
  border: 1px solid var(--accent-blue-color);
  color: var(--main-black-color);
}
.btn-save_type_large {
  margin: 0px;
  background: var(--gradient-blue-color);
  border: none;
  border-radius: var(--border-radius);
  color: var(--main-white-color);
}
.btn-save_type_large:hover  {
  cursor: pointer;
  user-select: auto;
  pointer-events: all;
  opacity: .8;
}
.btn-save_type_large_status_block {
  background: var(--slider-color);
  border-radius: var(--border-radius);
  box-shadow: none;
  user-select: none;
  pointer-events: none;
}
.btn-save_type_large_status_loading {
  background: var(--gradient-orange-color);
  cursor: default;
  user-select: none;
  pointer-events: none;
}
.btn-primary {
  margin: 0px;
  position: relative;
  width: 100%;
  padding: 11px;
  background: var(--gradient-blue-color);
  border: none;
  border-radius: var(--border-radius);
  color: var(--main-white-color);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  flex-shrink: 0;
  transition: all .3s ease-in-out;
}
.btn-primary:hover {
  cursor: pointer;
  opacity: .7;
}
.btn-more {
  position: relative;
  background: none;
  border: none;
  margin: 0px auto;
  padding: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: var(--accent-blue-color);
}
.btn-more_type_show::after {
  content: '';
  position: absolute;
  top: 0px;
  right: -20px;
  width: 16px;
  height: 16px;
  background-image: url('../../images/arrow-blue.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.btn-more_type_hide::after {
  content: '';
  position: absolute;
  top: 0px;
  right: -20px;
  width: 16px;
  height: 16px;
  background-image: url('../../images/arrow-blue.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}  
.btn-more_margin_top {
  margin: 16px auto 0px;
}
.upload-form {
  width: 100%;
  margin: 12px 0px 0px 0px;
}
.upload-form__container {
  width: 100%;
  display: flex;
  margin: 12px 0px 0px 0px;
}
.upload-form__section {
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: center;
}
.upload-form__field {
  width: 100%;
  margin: 0px;
  padding: 9px 16px 9px 16px;
  border: 2px solid var(--background-color);
  background: var(--main-grey-color);
  border-radius: var(--border-radius);
  display: flex; 
  justify-content: space-between;
  align-items: center;
  transition: all .3s ease-in-out;
}
.upload-form__field:hover {
  border: 2px solid var(--accent-grey-color);
  cursor: pointer;
}
.upload-form__text {
  margin: 0px 16px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.upload-form__icon {
  margin: 0px;
  width: 20px;
  height: 20px;
  background-image: url('../../images/button/upload-color.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .3s ease-in-out;
}
.upload-form__field:hover .upload-form__icon {
  background-image: url('../../images/button/upload-hover.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}
.upload-form__input {
  display: none;
}
.upload-form__buttons {
  margin: 0px 0px 0px 16px;
  display: flex;
}
.radio {
  display: block;
  cursor: pointer;
  margin: 16px 0px 0px 0px;
}
.radio input {
  display: none;
}
.radio input + span {
  font-size: 16px;
  padding-left: 22px;
  padding-top: 2px;
  display: block;
  position: relative;
}
.radio input + span:not(:empty) {
  padding-left: 30px;
}
.radio input + span:before, .radio input + span:after {
  content: '';
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 50%;
  left: 0;
  top: 0;
  position: absolute;
}
.radio input + span:before {
  background: var(--border-card-color);
  transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.radio input + span:after {
  background: var(--main-white-color);
  transform: scale(0.78);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}
.radio input:checked + span:before {
  transform: scale(1.04);
  background: var(--accent-blue-color);
}
.radio input:checked + span:after {
  transform: scale(0.4);
  transition: transform 0.3s ease;
}
.radio:hover input + span:before {
  transform: scale(0.92);
}
.radio:hover input + span:after {
  transform: scale(0.74);
}
.radio:hover input:checked + span:after {
  transform: scale(0.4);
}
.checkbox {
  margin: 16px 0px;
  display: block;
  cursor: pointer;
}
.checkbox input {
  display: none;
}
.checkbox input + span {
  font-size: 16px;
  padding-left: 22px;
  padding-top: 3px;
  display: block;
  position: relative;
}
.checkbox input + span:not(:empty) {
  padding-left: 30px;
}
.checkbox input + span:before, .checkbox input + span:after {
  content: '';
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 20%;
  left: 0;
  top: 0;
  position: absolute;
}
.checkbox input + span:before {
  background: var(--border-card-color);
  transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.checkbox input + span:after {
  background: var(--main-white-color);
  transform: scale(0.78);
  transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
}
.checkbox input:checked + span:before {
  transform: scale(1.04);
  background: var(--accent-blue-color);
  background-image: url('../../images/checkbox-img.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
}
.checkbox input:checked + span:after {
  transform: scale(0);
  transition: transform 0.1s ease;
}
.checkbox:hover input + span:before {
  transform: scale(0.92);
}
.checkbox:hover input + span:after {
  transform: scale(0.74);
}
.checkbox:hover input:checked + span:after {
  transform: scale(0);
}
.svg {
  width: 100%;
  height: 100%;
}
.rec.rec-carousel-wrapper {
  position: relative;
}
.rec.rec-arrow {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 2px solid var(--main-color);
  background-color: inherit;
  box-sizing: border-box;
  color: var(--main-color);
}
.rec.rec-arrow:hover:enabled {
  background-color: var(--main-color);
  color: var(--white-color);
}
.rec.rec-arrow:focus:enabled {
  background-color: var(--main-color);
  color: var(--white-color);
}
.rec.rec-arrow:hover:disabled {
  cursor: initial;
  background-color: inherit;
  border: 2px solid var(--border-card-color);
  color: var(--border-card-color);
}
.rec.rec-arrow-left {
  right: 100px;
  bottom: -100px;
}
.rec.rec-arrow-right {
  right: 20px;
  bottom: -100px;
}
.rec.rec-pagination {
  position: absolute;
  right: 0px;
  bottom: -36px;
}
.rec.rec-dot {
  width: 12px;
  height: 12px;
  background: var(--slider-color);
  border: none;
  box-shadow: none;
  border-radius: 50%;
}
.rec.rec-dot:hover {
  box-shadow: 0 0 1px 3px rgb(17 83 252 / 40%);
}
.rec.rec-dot:focus {
  box-shadow: 0 0 1px 3px rgb(17 83 252 / 40%);
}
.rec.rec-dot_active {
  background: var(--gradient-blue-color);
}
.status {
  margin: 0px;
  width: 100%;
  display: flex;
  align-items: center;
}
.status__icon {
  margin: 0px 12px 0px 0px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.status__icon_type_completed {
  background: var(--slider-color);
}
.status__icon_type_canceled {
  background: var(--accent-orange-color);
}
.status__icon_type_planned {
  background: var(--accent-blue-color);
}
.data__list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
.data__list_margin_top {
  margin: 12px 0px 0px 0px;
}
.data__item {
  margin: 0px 0px 12px 0px;
  display: flex;
  align-items: center;
}
.data__item:last-of-type {
  margin: 0px;
}
.data__text {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: var(--main-black-color);
}
.data__text_font_bold {
  margin: 0px 8px 0px 0px;
  font-weight: bold;
}
.data__text_font_active {
  margin: 0px 8px 0px 0px;
  color: var(--accent-blue-color);
  font-weight: bold;
}
.data__area {
  resize: none;
  width: 100%;
  padding: 11px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--title-color);
  box-sizing: border-box;
  border: none;
  outline: none;
  background: var(--main-grey-color);
  border-radius: var(--border-radius);
  overflow-y: auto;
}
.data__area_height_small {
  height: 120px;
}
.data__area_height_medium {
  height: 300px;
}
.data__control {
  width: 100%;
  margin: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
}
.data__control-text {
  margin: 0px 20px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: var(--accent-blue-color);
  transition: all .3s ease-in-out;
}
.data__control-text:last-of-type {
  margin: 0px;
}
.data__control-text:hover {
  cursor: pointer;
  opacity: .7;
}
.separate_type_empty {
  margin: 12px 0px;
}
.textarea {
  resize: none;
  width: 100%;
  min-height: 160px;
  padding: 9px 16px 9px 16px;
  border: 2px solid var(--background-color);
  margin: 8px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--title-color);
  box-sizing: border-box;
  outline: none;
  background: var(--main-grey-color);
  border-radius: var(--border-radius);
  transition: all .3s ease-in-out;
  overflow-y: auto;
}
.textarea:hover {
  border: 2px solid var(--accent-grey-color);
}
.textarea:active {
  border: 2px solid var(--accent-blue-color);
}
.textarea:focus {
  border: 2px solid var(--accent-blue-color);
}
.textarea_type_block {
  user-select: none;
  pointer-events: none;
}
.textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--accent-grey-color);
}
.textarea_height_minimal {
  max-height: 80px;
  min-height: 80px;
}
.textarea_height_small {
  max-height: 160px;
}
.textarea_height_medium {
  max-height: 300px;
}
.textarea_height_max {
  max-height: 100%;
  height: 100%;
}

@media all and (max-width: 1439px) {
  .container {
    max-width: 1240px;
    padding: 12px 20px;
  }
  .main-container {
    margin: 0px 0px 0px 252px;
  }
}

@media all and (max-width: 1279px) {
  .container {
    max-width: 1280px;
    padding: 76px 0px 20px 0px;
  }
  .main-container {
    margin: 0px;
  }
  .wrapper {
    margin-left: 0px;
    width: 100%;
  }
}

@media all and (max-width: 833px) {
  .container {
    padding: 60px 0px 16px 0px;
  }
  .icon_margin_left {
    margin: 0px 0px 0px 8px;
  }
  .btn-icon {
    width: 32px;
    height: 32px;
  }
  .btn-icon_margin_left {
    margin: 0px 0px 0px 8px;
  }
  .btn-icon::after  {
    top: 8px;
    left: 8px;
    background-size: 16px;
    width: 16px;
    height: 16px;
  }
  .btn-add-round {
    margin: 0px 0px 0px 8px;
  }
  .btn_type_large {
    flex-grow: 2;
    width: auto;
    padding: 7px;
    font-size: 13px;
    line-height: 16px;
  }
  .upload-form__container {
    flex-direction: column;
  }
  .upload-form__section {
    margin: 0px;
  }
  .upload-form__buttons {
    margin: 12px 0px 0px 0px;
  }
  .upload-form__field {
    padding: 6px 12px;
  }
  .upload-form__text {
    font-size: 13px;
    line-height: 16px;
  }
  .upload-form__icon {
    width: 16px;
    height: 16px;
    background-size: 16px;
  }
  .upload-form__field:hover .upload-form__icon {
    background-image: url('../../images/button/upload-hover.svg');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .rec.rec-slider-container {
    margin: 0px;
  }
}
