.program__list {
  width: 100%;
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: auto;
}
.program__item {
	width: 100%;
	min-height: 280px;
	margin: 0px;
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	background: var(--main-white-color);
	border: 2px solid var(--border-color);
	border-radius: var(--border-radius);
	box-shadow: 0px 8px 12px -8px rgba(218, 221, 229, 0.9);
}
.program__item_type_add {
	border: none;
	background: var(--gradient-blue-color);
	box-shadow: 0px 8px 12px -8px rgba(131, 122, 234, 0.9);
}
.program__item-header {
	width: 100%;
	margin: 0px;
	display: flex;
	align-items: center;
	min-height: 20px;
}
.program__item-header-btn {
	margin: 0px 0px 0px auto;
	display: flex;
	align-items: center;
}
.program__item-title {
	margin: 32px 0px 0px 0px;
	max-width: 80%;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: var(--main-black-color);
	transition: all .3s ease-in-out;
}
.program__item-title:hover {
	color: var(--accent-blue-color);
	cursor: pointer;
}
.program__item_type_add .program__item-title {
	color: var(--main-white-color);
}
.program__item_type_add .program__item-title:hover {
	color: var(--main-white-color);
	cursor: auto;
}
.program__item-description {
	margin: auto 0px 0px 0px;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: var(--main-black-color);
}