.test {
  width: 100%;
  margin: 0px;
  background-color: var(--main-grey-color);;
  display: flex;
  flex-grow: 2;
  border-radius: var(--border-radius);
}
.test__container {
  width: 100%;
  margin: 0px;
  padding: 16px;
  box-sizing: border-box;
}
.test__control {
  width: 100%;
  margin: 0px;
  display: grid;
  grid-template-columns: 1fr 40px 216px;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}
.test__control-type {
  width: calc(100% - 8px);
  margin: 0px 8px 0px 0px;
  padding: 9px 16px 9px 16px;
  border: 2px solid var(--accent-blue-color);
  box-sizing: border-box;
  background: var(--main-grey-color);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
}
.test__control-text {
  margin: 0px 22px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.test__control-text_weight_bold {
  font-weight: bold;
}
.test__caption-empty {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--accent-grey-color);
}
.test-question {
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
}
.test-question__title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
}
.test-question__title_mt_20 {
  margin-top: 20px;
}
.test-textarea {
  resize: none;
  width: 100%;
  max-height: 120px;
  min-height: 80px;
  padding: 9px 16px 9px 16px;
  border: 2px solid var(--background-color);
  margin: 8px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--title-color);
  box-sizing: border-box;
  outline: none;
  background: var(--main-white-color);
  border-radius: var(--border-radius);
  transition: all .3s ease-in-out;
  overflow-y: auto;
}
.test-textarea:hover {
  border: 2px solid var(--accent-grey-color);
}
.test-textarea:active {
  border: 2px solid var(--accent-blue-color);
}
.test-textarea:focus {
  border: 2px solid var(--accent-blue-color);
}
.test-textarea_type_block {
  user-select: none;
  pointer-events: none;
}
.test-textarea::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--accent-grey-color);
}
.textarea_height_minimal {
  max-height: 80px;
  min-height: 80px;
}
.test-answer__list {
  margin: 12px 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

