.droppable-column {
  display: flex;
  margin: 20px 0px 0px 0px;
}
.droppable-column__container {
  width: 100%;
}
.droppable__count-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding: 0px;
  margin: 0px 0px 8px 0px;
}
.droppable-column__list {
  list-style: none;
  padding: 0px;
  min-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
}