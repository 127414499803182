.levels {
  width: 100%;;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-grow: 2;
}
.levels_direction_row {
  flex-direction: row;
}
.levels_direction_column {
  flex-direction: column;
}
.level__tab {
  width: 100px;
  margin: 0px 20px 0px 0px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  border-radius: var(--border-radius);
  background-color: var(--main-grey-color);
}
.level__tab:last-of-type {
  margin: 0px;
}
.levels__tab-title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  font-size: 24px;
  line-height: 28px;
  color: var(--main-black-color);
}
.levels__container {
  width: 100%;
  margin: 0px 20px 0px 0px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  border-radius: var(--border-radius);
  background-color: var(--main-grey-color);
}
.levels__container:last-of-type {
  margin: 0px;
}
.levels__header {
  width: 100%;
  min-height: 40px;
  margin: 0px;
  padding-bottom: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--background-color);
}
.levels__header-title {
  margin: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-black-color);
}
.levels__header-info {
  margin: 0 0 0 8px;
  width: 24px;
  height: 24px;
  background-image: url('../../images/info.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  cursor: pointer;
}
.levels__header-btn-container {
  margin: 0px 0px 0px auto;
  display: flex;
  align-items: center;
}
.levels__list {
  margin: 12px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  overflow-y: scroll;
}
.levels__item {
  width: calc(100% - 8px);
  margin: 0px 8px 8px 0px;
  padding: 8px 16px 8px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background-color: var(--main-white-color);
  border-radius: var(--border-radius);
}
.levels__item_type_dragging {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.levels__item:last-of-type {
  margin: 0px 8px 0px 0px;
}
.levels__item_type_active {
  transition: all .3s ease-in-out;
  background-color: var(--background-color);
}
.levels__item_type_open:hover {
  background-color: var(--background-color);
  cursor: pointer;
}
.levels__item-header {
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: center;
}
.levels__item-title {
  margin: 12px 0px 0px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  white-space: normal;
  color: var(--main-black-color);
}
.levels__item-subtitle {
  margin: 8px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  white-space: normal;
  color: var(--accent-grey-color);
}
.levels__item-header-btn-container {
  margin: 0px 0px 0px auto;
  display: flex;
  align-items: center;
}
.levels__item-row {
  margin: 8px 0px 0px 0px;
  display: flex;
  align-items: center;
}
.levels__children-list {
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.levels__children-item {
  width: 14px;
  height: 14px;
  margin: 8px 4px 0px 0px;
  background: var(--slider-color);
  border-radius: 50%;
}
.levels__children-item:last-of-type {
  margin: 8px 0px 0px 0px;
}
.levels__children-item_type_product {
	background-color: var(--product-color);
}
.levels__children-item_type_stage {
	background-color: var(--stage-color);
}
.levels__children-item_type_process {
	background-color: var(--process-color);
}
.levels__children-item_type_ability {
	background-color: var(--ability-color);
}
.levels__children-item_type_knowledge {
	background-color: var(--knowledge-color);
}
.levels__children-item_type_discipline {
	background-color: var(--discipline-color);
}
.levels__children-item-count {
  margin: 8px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: var(--accent-grey-color);
}
.levels__item-empty {
  margin: 8px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--accent-grey-color);
}

.levels-carousel__container {
  white-space: nowrap;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}
.levels-carousel {
  width: 100%;
  margin: 0px;
  display: flex;
  flex-grow: 2;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
}
.levels-carousel__item {
  margin: 0px;
  width: calc(100% / 2 - 16px);
  padding: 0px 8px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}
.levels-carousel__control {
  margin: 0px 0px 12px 0px;
  width: 100%;
  padding: 0px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.levels-carousel__button {
  margin: 0px 12px 0px 0px;
  padding: 6px 14px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: var(--accent-blue-color);
  color: var(--main-white-color);
  border: none;
  outline: none;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  border-radius: var(--border-radius);
  transition: all .3s ease-in-out;
}
.levels-carousel__button:hover {
  cursor: pointer;
  opacity: .7;
}
.levels-carousel__button_type_active {
  background-color: var(--main-grey-color);
  color: var(--main-black-color);
  pointer-events: none;
  user-select: none;
}
.levels-carousel__button_type_active:hover {
  cursor: default;
  opacity: 1;
}
.levels__breadcrumb {
  margin: 4px 0px 8px 0px;
  padding: 0px 8px;
  min-height: 18px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  white-space: normal;
  color: var(--main-black-color);
}
