.btn-icon {
  position: relative;
  margin: 0px;
  padding: 0px;
  border: none;
  border-radius: var(--border-radius);
  outline: none;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  color: var(--main-black-color);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  background: var(--slider-color);
  text-decoration: none;
  transition: all .3s ease-in-out;
}
.btn-icon::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  background-size: 20px;
  width: 20px;
  height: 20px;
}
.btn-icon_margin_left {
  margin: 0px 0px 0px 12px;
}
.btn-icon_color_blue {
  color: var(--main-white-color);
  background: var(--gradient-blue-color);
}
.btn-icon_color_blue:hover {
  cursor: pointer;
  user-select: auto;
  pointer-events: all;
  opacity: .8;
  box-shadow: 0px 8px 12px -8px rgba(130, 122, 233, 0.9);
}
.btn-icon_color_orange {
  color: var(--main-white-color);
  background: var(--gradient-orange-color);
}
.btn-icon_color_orange:hover {
  cursor: pointer;
  user-select: auto;
  pointer-events: all;
  opacity: .8;
  box-shadow: 0px 8px 12px -8px rgba(213, 102, 0, 0.9);
}
.btn-icon_type_create::after  {
  background-image: url('../../../images/icons/main-white/plus.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_edit::after   {
  background-image: url('../../../images/icons/main-white/edit.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_remove::after  {
  background-image: url('../../../images/icons/main-white/remove.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_upload::after   {
  background-image: url('../../../images/icons/main-white/upload.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_download::after   {
  background-image: url('../../../images/icons/main-white/download.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_cancel::after  {
  background-image: url('../../../images/icons/main-white/plus.svg');
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(45deg);
}
.btn-icon_type_copy::after {
  background-image: url('../../../images/icons/main-white/copy.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_view::after {
  background-image: url('../../../images/icons/main-white/view.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_file-export::after {
  background-image: url('../../../images/icons/main-white/file-export.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_collapse::after {
  background-image: url('../../../images/icons/main-white/collapse.svg');
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.btn-icon_type_expand::after {
  background-image: url('../../../images/icons/main-white/expand.svg');
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.btn-icon_type_refresh::after {
  background-image: url('../../../images/icons/main-white/refresh.svg');
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.btn-icon_type_open::after {
  background-image: url('../../../images/icons/main-white/open.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_link::after {
  background-image: url('../../../images/icons/main-white/link.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_table::after {
  background-image: url('../../../images/icons/main-white/table.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_users::after {
  background-image: url('../../../images/icons/main-white/users.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_type_place::after {
  background-image: url('../../../images/icons/main-white/place.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.btn-icon_status_block {
  background: var(--slider-color);
  box-shadow: none;
  user-select: none;
  pointer-events: none;
}

@media all and (max-width: 833px) {
  .btn-icon {
    width: 32px;
    height: 32px;
  }
  .btn-icon_margin_left {
    margin: 0px 0px 0px 8px;
  }
  .btn-icon::after  {
    top: 8px;
    left: 8px;
    background-size: 16px;
    width: 16px;
    height: 16px;
  }
}
