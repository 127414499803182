.navigation {
  margin: 0px 0px 20px 0px;
  display: flex;
  align-items: center;
  width: 100%;
}
.navigation__btn-back {
  margin: 0px;
  min-width: 164px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 20px;
  background: var(--main-white-color);
  border-radius: var(--border-radius);
  border: none;
  transition: all .3s ease-in-out;
}
.navigation__btn-back:hover {
  cursor: pointer;
}
.navigation__btn-back-text {
  margin: 0px 20px 0px 0px;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: var(--main-black-color);
}
.navigation__btn-back-arrow {
  width: 24px;
  height: 24px;
  background-image: url('../../images/arrow.svg');
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
  transform: rotate(270deg);
}
.navigation__list {
  margin: 0px 0px 0px auto;
  padding: 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.navigation__item {
  margin: 0px 22px 0px 0px;
}
.navigation__item:last-of-type {
  margin: 0px;
}
.navigation__item-container {
  width: 40px;
  height: 40px;
  background: var(--main-white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
}
.navigation__item-container:hover {
  background: var(--gradient-blue-color);
  cursor: pointer;
}
.navigation__item-container_type_active {
  background: var(--gradient-blue-color);
  user-select: none;
  pointer-events: none;
}
.navigation__btn {
  width: 24px;
  height: 24px;
}
.navigation__btn_type_home {
  background-image: url('../../images/menu/home-color.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.navigation__item-container:hover .navigation__btn_type_home {
  background-image: url('../../images/menu/home.svg');
}
.navigation__item-container_type_active .navigation__btn_type_home {
  background-image: url('../../images/menu/notification.svg');
}
.navigation__btn_type_notification {
  background-image: url('../../images/menu/notification-color.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.navigation__item-container:hover .navigation__btn_type_notification {
  background-image: url('../../images/menu/notification.svg');
}
.navigation__item-container_type_active .navigation__btn_type_notification {
  background-image: url('../../images/menu/notification.svg');
}
.navigation__btn_type_calendar {
  background-image: url('../../images/menu/calendar-color.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.navigation__item-container:hover .navigation__btn_type_calendar {
  background-image: url('../../images/menu/calendar.svg');
}
.navigation__item-container_type_active .navigation__btn_type_calendar {
  background-image: url('../../images/menu/calendar.svg');
}
.navigation__btn_type_exit {
  background-image: url('../../images/menu/exit-color.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.navigation__item-container:hover .navigation__btn_type_exit {
  background-image: url('../../images/menu/exit.svg');
}
.navigation__item-container_type_active .navigation__btn_type_exit {
  background-image: url('../../images/menu/exit.svg');
}

@media all and (max-width: 833px) {
  .navigation {
    margin: 0px 0px 16px 0px;
  }
  .navigation__btn-back {
    min-width: max-content;
    min-height: 32px;
    flex-grow: 2;
    padding: 4px 8px 4px 16px;
  }
  .navigation__btn-back-arrow {
    width: 16px;
    height: 16px;
  }
  .navigation__btn-empty {
    min-width: max-content;
    min-height: 32px;
    flex-grow: 2;
    padding: 4px 8px 4px 16px;
  }
  .navigation__btn-back-text {
    margin: 0px 16px 0px 0px;
    font-size: 15px;
    line-height: 18px;
  }
  .navigation__nav-list {
    margin: 0px 0px 0px 12px;
  }
  .navigation__nav-item {
    margin: 0px 12px 0px 0px;
  }
  .navigation__nav-item-container {
    width: 32px;
    height: 32px;
  }
  .navigation__nav-btn {
    width: 16px;
    height: 16px;
  }
}
