.select-popup {
  margin: 4px 0px 0px 0px;
  min-width: 164px;
  min-height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 16px;
  border: 2px solid var(--background-color);
  box-sizing: border-box;
  background: var(--main-grey-color);
  border-radius: var(--border-radius);
  transition: all .3s ease-in-out;
}
.select-popup:hover {
  border: 2px solid var(--accent-grey-color);
}
.select-popup_status_open {
  border: 2px solid var(--accent-blue-color);
}
.select-popup_status_open:hover {
  border: 2px solid var(--accent-blue-color);
}
.select-popup:hover {
  cursor: pointer;
}
.select-popup_width_full {
  width: 100%;
}
.select-popup_layout_white {
  background: var(--main-white-color);
}
.select-popup__main {
  margin: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.select-popup__text {
  margin: 0px 22px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.select-popup__arrow {
  margin: 0px;
  width: 18px;
  height: 18px;
  background-image: url('../../../images/arrow.svg');
  background-position: center;
  background-size: 18px;
  background-repeat: no-repeat;
  transform: rotate(0deg);
  transition: all .3s ease-in-out;
}
.select-popup__arrow_status_open {
  transform: rotate(180deg);
}
.select-popup__options-container {
  width: 100%;
  margin: 8px 0px 0px 0px;
  background: var(--main-grey-color);
  border-radius: 8px 8px 8px 8px;
  display: none;
  position: absolute;
  top: 38px;
  left: 0px;
  transition: all .3s ease-in-out;
  z-index: 100;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px -8px rgb(166 166 166 / 90%);
}
.select-popup__options-container_status_open {
  display: block;
}
.select-popup__options-list {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  max-height: 180px;
  overflow-y: auto;
}
.select-popup__options-item {
  margin: 0px 20px;
  padding: 11px 0px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--slider-color);
}
.select-popup__options-item:last-of-type {
  border-bottom: none;
}
.select-popup__options-text {
  margin: 0px;
  font-style: normal;
  font-weight: normal; 
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
}
.select-popup__options-item:hover .select-popup__options-text {
  color: var(--accent-blue-color);
}
@media all and (max-width: 833px) {
  .select-popup {
    min-width: 140px;
    min-height: 32px;
    padding: 6px 11px;
  }
  .select-popup__text {
    margin: 0px 12px 0px 0px;
    font-size: 12px;
    line-height: 16px;
  }
  .select-popup__arrow {
    width: 16px;
    height: 16px;
    background-size: 16px;
  }
  .select-popup__options-container {
    top: 30px;
  }
  .select-popup__options-item {
    margin: 0px 12px;
  }
  .select-popup__options-text {
    font-size: 12px;
    line-height: 16px;
  }
}