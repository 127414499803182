.program-info {
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.program-info__title {
  margin: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--main-black-color);
}
.program-info__subtitle {
  margin: 8px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
}
.program-info__text_type_accent {
  font-weight: 700;
  color: var(--accent-blue-color);
}
.program-info-description {
  margin: 20px 0px 30px 0px;
  width: 100%;
  display: flex;
}
.program-info-description__list {
  margin: 0px 20px 0px 0px;
  padding: 0px;
  width: calc(50% - 20px);
  list-style-type: none;
}
.program-info-description__item {
  margin: 0px 0px 20px 0px;
}
.program-info-description__item:last-of-type {
  margin: 0px;
}
.program-info-description__caption {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
}
.program-info-description__name {
  margin: 12px 0px 0px 0px;
  width: 100%;
  padding: 11px 16px 11px 16px;
  box-sizing: border-box;
  background: var(--main-grey-color);
  border-radius: var(--border-radius);
  color: var(--main-black-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}
.program-info-description__text {
  margin: 0px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.program-info-description__textarea {
  margin: 12px 0px 0px 0px;
  width: 100%;
  padding: 11px 16px 11px 16px;
  box-sizing: border-box;
  background: var(--main-grey-color);
  color: var(--main-black-color);
  border-radius: var(--border-radius);
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  flex-grow: 1;
}