.home-page {
  margin: 0px;
  position: relative;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 5;
  display: flex;
  flex-direction: column;
}
.home-page__overlay {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background: linear-gradient(315deg, rgba(17, 83, 252, 0.9) 0%, rgba(85, 129, 241, 0.9) 84.37%);
  backdrop-filter: blur(2px);
  z-index: -5;
}
.home-page__background {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background-image: url('../../images/homepage/background.jpg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -10;
}
.home-page__link {
  position: relative;
  margin: 16px auto 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  transition: all .3s ease-in-out;
}
.home-page__link:hover {
  cursor: pointer;
  opacity: .7;
}
.home-page__info {
  margin: auto 56px 56px 56px;
  display: flex;
  justify-content: space-between;
}
.home-page__info-column {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.home-page__info-title {
  margin: 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}
.home-page__info-email {
  position: relative;
  margin: 16px 0px 0px 0px;
  padding: 0px 0px 0px 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}
.home-page__info-email::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  top: -1px;
  left: 0px;
  background-image: url('../../images/homepage/mail.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.home-page__info-phone {
  position: relative;
  margin: 16px 0px 0px 0px;
  padding: 0px 0px 0px 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}
.home-page__info-phone::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  top: -1px;
  left: 0px;
  background-image: url('../../images/homepage/phone.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.home-page__info-copy {
  position: relative;
  margin: auto 0px 0px 0px;
  padding: 0px 0px 0px 30px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}
.home-page__info-copy::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  top: -1px;
  left: 0px;
  background-image: url('../../images/homepage/copyright.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media all and (max-width: 833px) {
  .home-page {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
  }
  .home-page__overlay {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
  }
  .home-page__background {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
  }
  .home-page__info {
    margin: auto 40px 40px 40px;
    flex-direction: column;
  }
  .home-page__link {
    margin: 12px auto 0px;
  }
  .home-page__info-email {
    margin: 12px 0px 0px 0px;
  }
  .home-page__info-phone {
    margin: 12px 0px 0px 0px;
  }
  .home-page__info-copy {
    margin: 32px 0px 0px 0px;
  }
}
