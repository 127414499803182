.table {
  margin: 0px;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 2;
}
.table__title {
  margin: 0px 0px 12px 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
}
.table__title_margin_top {
  margin: 20px 0px 12px 0px;
}
.table__container {
  margin: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table__container_margin_top {
  margin: 12px 0px 0px 0px;
}
.table__header {
  margin: 0px;
  width: 100%;
  display: flex;
  align-items: center;
}
.table__main-column {
  margin: 0px 12px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--slider-color);
}
.table__main-column_type_full {
  margin: 0px;
}
.table__main-column_type_empty {
  margin: 0px 32px 0px 0px;
}
.table__row:last-of-type .table__main-column {
  border: none;
}
.table__row {
  margin: 0px;
  width: 100%;
  display: flex;
  align-items: center;
}
.table__row_type_complete {
  background-color: var(--main-grey-color);
}
.table__column {
  margin: 0px 12px 0px 0px;
  padding: 16px 0px;
  box-sizing: border-box;
}
.table__column:last-of-type {
  margin: 0px;
}
.table__main-column_type_block {
  background-color: var(--main-grey-color);
}
.table__column_type_header {
  margin: 0px 12px 0px 0px;
  padding: 0px 0px 16px 0px;
  box-sizing: border-box;
}
.table__column_type_header:last-of-type {
  margin: 0px;
}
.table__column_direction_row {
  display: flex;
  align-items: center;
}
.table__cell-badge-list {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.table__cell-badge-list_position_bottom {
  margin: 4px 0px 0px 0px;
}
.table__cell-badge-list_position_right {
  margin: 0px 0px 0px auto;
}
.table__cell-badge-list_position_left {
  margin: 0px 0px 0px 8px;
}
.table__cell-badge-item {
  margin: 0px;
}
.table__cell-badge {
  margin: 0px;
  padding: 4px 8px;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
  letter-spacing: 1.2;
  border-radius: var(--border-radius);
  color: var( --main-white-color);
  user-select: none;
  pointer-events: none;
}
.table__cell-badge_color_orange {
  background: var(--gradient-orange-color);
}
.table__cell-badge_color_blue {
  background: var(--gradient-blue-color);
}
.table__main {
  margin: 0px;
  padding: 0px 8px 0px 0px;
  list-style-type: none;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  overflow-y: scroll;
}
.table__main_height_smallest {
  height: 101px;
}
.table__main_height_small {
  height: 152px;
}
.table__main_height_large {
  height: 33vh;
}
.table__main_scroll_auto {
  overflow-y: auto;
  padding: 0px;
}
.table__column_type_btn-header {
  padding: 0px 0px 0px 20px;
  margin: 0px 32px 0px 0px;
  opacity: 0;
  box-sizing: border-box;
  user-select: none;
  pointer-events: none;
}
.table__column_type_btn-header:last-of-type {
  margin: 0px 20px 0px 0px;
}
.table__column_type_count {
  width: 40px;
  flex-shrink: 0;
}
.table__column_type_date {
  width: 88px;
  flex-shrink: 0;
}
.table__column_type_name {
  width: 100%;
  flex-shrink: 1;
}
.table__column_type_teacher {
  width: 180px;
  flex-shrink: 0;
}
.table__column_type_text {
  width: 100%;
  flex-shrink: 1;
}
.table__column_type_status {
  width: 124px;
  flex-shrink: 0;
}
.table__column_type_control {
  width: 64px;
  flex-shrink: 0;
}
.table__column_type_link {
  width: 124px;
  flex-shrink: 0;
}
.table__column_type_record {
  width: 124px;
  flex-shrink: 0;
}
.table__column_type_btn {
  display: flex;
  flex-shrink: 0;
  padding: 0px;
  gap: 12px;
}
.table__column_type_small {
  width: 64px;
  flex-shrink: 0;
}
.table__column_type_medium {
  width: 86px;
  flex-shrink: 0;
}
.table__column_type_large {
  width: 124px;
  flex-shrink: 0;
}
.table__column_type_full {
  width: 100%;
  flex-shrink: 1;;
}
.table__text {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--main-black-color);
  text-decoration: none;
  transition: all .3s ease-in-out;
}
.table__text_weight_bold {
  font-weight: bold;
}
.table__text_type_empty {
  color: var(--accent-grey-color);
}
.table__text_type_error {
  color: var(--accent-orange-color);
}
.table__text_type_active:hover {
  color: var(--accent-blue-color);
  cursor: pointer;
}
.table__text_align_center {
  text-align: center;
}
.table__text_color_orange {
  color: var(--accent-orange-color);
}
.table__text_type_cut {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.table__link {
  color: var(--accent-blue-color);
  text-decoration: none;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all .3s ease-in-out;
}
.table__link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.table__text_type_header {
  font-weight: bold;
}
.table__text_margin_left {
  margin: 0px 0px 0px 16px;
}
.table__text_margin_top {
  margin: 16px 0px 0px 0px;
}
.table__caption_type_empty {
  margin: 16px 0px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--accent-grey-color);
}
.table__btn {
  margin: 0px;
}
.table__status-list {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  list-style-type: none;
}
.table__status-item {
  margin: 0px 4px 0px 0px;
  width: 18px;
  height: 18px;
  background-color: var(--background-color);
  border-radius: 50%;
  flex-shrink: 0;
}
.table__status-item:last-of-type {
  margin: 0px;
}
.table__status-item_type_active {
  background-color: var(--accent-blue-color);
}
@media all and (max-width: 833px) {
  .table__title {
    margin: 0px 0px 8px 0px;
    font-size: 12px;
    line-height: 16px;
  }
  .table__title_margin_top {
    margin: 20px 0px 8px 0px;
  }
  .table__column_type_btn-header:last-of-type {
    margin: 0px 8px 0px 0px;
  }
  .table__column_type_btn-header-with-scroll:last-of-type
   {
    margin: 0px 20px 0px 0px;
  }
  .table__caption_type_empty {
    margin: 8px 0px 0px 0px;
    font-size: 12px;
    line-height: 14px;
  }
  .table__column {
    padding: 12px 0px;
  }
  .table__column_type_btn {
    padding: 0px;
  }
}